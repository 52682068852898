import React from "react";

export default function Circle() {
  return (
    <section id="features" className="benefits relative">
      <div className="ribbon-yellow visible-xs">
        <a
          href="#promo"
          className="ease mb0 red underline lato-heavy mb-0 relative inline-block"
        >
          &raquo; Ver opciones de combo
        </a>
        <p className="mb-0 small green especial2">
          *Precio especial para envíos internacionales.
        </p>
      </div>
      <div className="top text-center">
        <p className="mb-0 yellow montserrat-semibold yellow py-1 ls-10">
          ALIVIA
        </p>
      </div>
      <div className="content shadow">
        <div className="container">
          <ul className="mb-0 list-unstyled list-inline row features">
            <li className="col-xs-12 col-sm-4 text-center">
              <div className="icon">
                <img
                  alt="benefits-1"
                  src={process.env.PUBLIC_URL + "/img/feat-1.png"}
                  className="img-responsive mx-auto relative rotate-hover"
                />
              </div>
              <p className="mb-0 h4 orange">
                <strong className="clearfix">Alivia tu dolor!</strong>
              </p>
            </li>
            <li className="col-xs-12 col-sm-4 text-center">
              <div className="icon">
                <img
                  alt="benefits-2"
                  src={process.env.PUBLIC_URL + "/img/feat-2.png"}
                  className="img-responsive mx-auto relative rotate-hover"
                />
              </div>
              <p className="mb-0 h4 green">
                <strong className="clearfix">Alivia la diabetes!</strong>
              </p>
            </li>
            <li className="col-xs-12 col-sm-4 text-center">
              <div className="icon">
                <img
                  alt="benefits-3"
                  src={process.env.PUBLIC_URL + "/img/feat-3.png"}
                  className="img-responsive mx-auto relative rotate-hover"
                />
              </div>
              <p className="mb-0 h4 brown">
                <strong className="clearfix">Alivia tu ansiedad!</strong>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
