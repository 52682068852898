import React from "react";

// Components
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children }: any) {
  return (
    <>
      <></>
      <Header />
      <div>{children}</div>
      <Footer />
      <></>
    </>
  );
}
