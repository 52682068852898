import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function Terms() {
  const [open, setOpen] = React.useState(false);

  // @ts-ignore
  const handleClose = (value) => {
    setOpen(value);
  };

  return (
    <div>
      <Link
        to="/"
        data-toggle="modal"
        data-target=".bs-example-modal-lg"
        data-content="content-politicas"
        className="yellow-dark  trigger-modal"
        onClick={() => handleClose(true)}
      >
        Politicas de Privacidad
      </Link>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={0}>
            <div className="modal-body content-terminos">
              <h1 className="h3 lato-bold green">Términos y Condiciones</h1>
              <p>
                <strong>
                  KEEP OUT OF THE REACH OF CHILDREN STORE PRODUCT AT ROOM
                  TEMPERATURE. DO NOT EXPOSE TO EXCESSIVE HEAT OR MOISTURE.
                  WARNING:
                </strong>
                Consult a physician or licensed qualified health care
                professional before using this product if you are being treated
                for any medical condition or are taking any medication.
                Discontinue immediately if any side effects occur. Do not use if
                safety seal is broken or missing.
              </p>
              <p>
                NOT FOR USE BY INDIVIDUALS UNDER THE AGE OF 18 YEARS. DO NOT USE
                IF YOU ARE PREGNANT OR NURSING.
              </p>
              <p>
                <strong>
                  MANTENER FUERA DEL ALCANCE DE LOS NIÑOS ALMACENAR EL PRODUCTO
                  A TEMPERATURA AMBIENTE. NO EXPONER AL CALOR EXCESIVO NI A LA
                  HUMEDAD. ADVERTENCIA:
                </strong>
                Consulte a un médico o profesional de la salud calificado y
                autorizado antes de usar este producto si usted está recibiendo
                tratamiento para alguna condición médica o si está tomando algún
                medicamento. Suspenda inmediatamente si se producen efectos
                secundarios. No lo use si el sello de seguridad está roto o no
                está presente.
              </p>
              <p>
                NO USE EN MENORES DE 18 AÑOS. NO LO USE SI USTED ESTÁ EMBARAZADA
                O LACTANDO.
              </p>
              <p>
                These statements have not been evaluated by the FDA. This
                product is not intended to diagnose, treat, cure or prevent any
                disease.
              </p>
              <p>
                "Representations regarding the efficacy and safety of Aceite
                Alivia have not been evaluated by the Food and Drug
                Administration. The FDA only evaluates foods and drugs, not
                supplements like these products. These products are not intended
                to diagnose, prevent, treat, or cure any disease. Click here
                aceitealivia.com to find evidence of a test, analysis, research,
                or study describing the benefits, performance or efficacy of
                Aceite Alivia based on the expertise of relevant professionals."
              </p>
              <p>
                Any claim that is not substantiated through at least one test,
                analysis, research report or study that has been conducted and
                evaluated in an objective manner by qualified persons and is
                generally accepted in the profession to yield accurate and
                reliable results.
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
