import React from "react";

export default function Persons() {
  return (
    <section id="pictures" className="pictures bg-orange shadow">
      <div className="content">
        <div className="container">
          <div className="clearfix icon-top hidden-xs">
            <img
              src={process.env.PUBLIC_URL + "img/bg-bottom-1-icon.png"}
              className="img-responsive mx-auto"
              alt="bg"
            />
          </div>
          <ul className="mb-0 list-unstyled list-inline pics">
            <li className="px-2">
              <img
                src={process.env.PUBLIC_URL + "img/foto-1.jpg"}
                className="img-responsive"
                alt="1"
              />
            </li>
            <li className="px-2">
              <img
                src={process.env.PUBLIC_URL + "img/foto-2.jpg"}
                className="img-responsive"
                alt="2"
              />
            </li>
            <li className="px-2">
              <img
                src={process.env.PUBLIC_URL + "img/foto-3.jpg"}
                className="img-responsive"
                alt="3"
              />
            </li>
            <li className="px-2">
              <img
                src={process.env.PUBLIC_URL + "img/foto-4.jpg"}
                className="img-responsive"
                alt="4"
              />
            </li>
            <li className="px-2">
              <img
                src={process.env.PUBLIC_URL + "img/foto-5.jpg"}
                className="img-responsive"
                alt="5"
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
