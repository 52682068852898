import React from "react";

export default function Hero() {
  return (
    <>
      <div className="site-wrapper full-width">
        <section id="home" className="home">
          <div className="container">
            <div className="clearfix no-gutter">
              <div className="col-md-6">
                <h4 className="text-uppercase red h4 mb-0 lato-heavy sumario">
                  Fortalece tu sistema inmunológico
                </h4>
                <h2 className="h2 green montserrat mt-1 titulo">
                  <strong>¡Defiende tu salud!</strong>
                  <br className="hidden-xs" /> Siente La Libertad En Unas Gotas.
                </h2>
                <p className="montserrat red line-top py-2 mb-0">
                  <strong>La solución que te alivia las enfermedades</strong>
                  <br className="hidden-xs" /> como la diabetes, insomnio,
                  inflamación, depresión, acné, cáncer, entre muchas otras.
                </p>
                <div className="oferta-home">
                  <div className="ribbon-green">
                    <p className="white ahora montserrat-bold mb-0 h5">
                      A partir de
                    </p>
                    <p className="mb-0 price relative">
                      <span className="symbol lato-heavy yellow">$</span>
                      <span className="number lato-heavy yellow">89</span>
                      <span className="decimal lato-heavy yellow">.95</span>
                      <span className="currency lato-heavy yellow">USD</span>
                    </p>
                    <p className="mb-0 price line-decline white montserrat text-center">
                      Antes: $99.95USD
                    </p>
                    <div className="text-center">
                      <a
                        href="tel:1-855-862-0427"
                        className="btn btn-primary mx-auto"
                      >
                        ¡Llama ahora!
                      </a>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="ribbon-yellow hidden-xs">
                    <a
                      href="#promo"
                      className="ease mb0 red underline lato-heavy mb-0 relative inline-block"
                    >
                      &raquo; Ver opciones de combo
                    </a>
                    <p className="mb-0 small green especial2">
                      *Precio especial para envíos internacionales.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-6 pt-6">
                <img
                  src={process.env.PUBLIC_URL + "/img/frascos-alivia.png"}
                  alt="hero"
                  className="img-responsive"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
