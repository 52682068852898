import React from "react";

export default function Extends() {
  return (
    <section id="beneficios" className="beneficios bg-gray shadow">
      <div className="container">
        <div className="row" style={{ display: "flex" }}>
          <div className="col-sm-9 f-n text-center mx-auto">
            <h2 className="main-title inline-block green underline mb-0 mt-0 lato-bold relative">
              Beneficios
            </h2>
            <h5 className="subtitle orange lato-bold mb-0 mt-0 py-4">
              Una nueva investigación está vinculando estos beneficios a una
              serie de procesos en el cuerpo, que contribuyen a un sistema
              inmunológico saludable y estabilizan la salud neurológica. Reduce
              la inflamación en todo el cuerpo y está demostrando ser efectivo
              para enfermedades como la artritis y la enfermedad de Crohn.
            </h5>
            <p className="desc"></p>
          </div>
        </div>
        <div className="row items-container">
          <div className="col-sm-4 col-md-3 col-md-offset-1 text-center item box item-1 space-top">
            <div className="icon inline-block">
              <span className="green pull-right lato-bold rotate-90 hidden-xs hidden-sm right">
                <img
                  src={process.env.PUBLIC_URL + "/img/plus-green.png"}
                  width={18}
                  height={18}
                  className="mr-2"
                  alt="increase-energy"
                />
                Incrementa tu energía
              </span>
              <img
                src={process.env.PUBLIC_URL + "/img/icon-8.png"}
                className="img-responsive mx-auto relative"
                alt="icon-8"
              />
            </div>
            <h3 className="h3 lato-heavy orange">ENERGÍA</h3>
            <p className="mb-0">
              Es conocido por su efecto calmante sobre el consumidor, también
              induce una sensación revitalizante y elimina el aletargamiento.
            </p>
          </div>
          <div className="col-sm-4 text-center item box item-2">
            <div className="icon inline-block">
              <span className="orange pull-right lato-bold rotate-90 hidden-xs hidden-sm right">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-orange.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Previene la Diabetes <br />y sobrepeso
              </span>
              <img
                src={process.env.PUBLIC_URL + "img/icon-1.png"}
                className="img-responsive mx-auto relative"
                alt="icon-1"
              />
            </div>
            <h3 className="h3 lato-heavy green">ANTICANCERÍGENO</h3>
            <p className="mb-0">
              Uno de los campos donde nuestro producto Alivia se ha mostrado más
              esperanzador y eficaz es la reducción de tumores, al inhibir la
              proliferación de células cancerígenas.
            </p>
          </div>
          <div className="com-sm-4 col-md-3 text-center item box item-3 space-top">
            <div className="icon inline-block">
              <span className="green pull-right lato-bold rotate-90 hidden-xs hidden-sm left">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-green.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Mejora tu metabolismo
              </span>
              <img
                src={process.env.PUBLIC_URL + "img/icon-2.png"}
                className="img-responsive mx-auto relative"
                alt="plus"
              />
            </div>
            <h3 className="h3 lato-heavy orange">METABOLISMO</h3>
            <p className="mb-0">
              Investigaciones recientes muestran que Alivia afecta directamente
              el metabolismo del cuerpo en una variedad de formas .
            </p>
          </div>
        </div>
        <div className="row items-container">
          <div className="col-sm-4 text-center item box item-4 space-top-2 space-bottom-2">
            <div className="icon inline-block">
              <span className="orange pull-right lato-bold rotate-90 hidden-xs hidden-sm right">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-orange.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Disminuye la ansiedad
              </span>
              <img
                src={process.env.PUBLIC_URL + "img/icon-7.png"}
                className="img-responsive mx-auto relative"
                alt="icon-7"
              />
            </div>
            <h3 className="h3 lato-heavy green">ANSIEDAD</h3>
            <p className="mb-0">
              Alivia ha resultado prometedor para aliviar los síntomas de la
              ansiedad asociados con dos trastornos muy frecuentes: el trastorno
              por estrés postraumático (TEPT) y el trastorno obsesivo-compulsivo
              (TOC).
            </p>
          </div>
          <div className="col-sm-4 text-center item box item-5 hidden-xs">
            <img
              src={process.env.PUBLIC_URL + "img/botellas-alivia.png"}
              className="img-responsive"
              alt="botellas"
            />
          </div>
          <div className="col-sm-4 text-center item box item-6 space-top-2 space-bottom-2">
            <div className="icon inline-block">
              <span className="orange pull-right lato-bold rotate-90 hidden-xs hidden-sm left">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-orange.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Disminuye la <br />
                inflamación y dolor
              </span>
              <img
                src={process.env.PUBLIC_URL + "img/icon-3.png"}
                className="img-responsive mx-auto relative"
                alt="icon-3"
              />
            </div>
            <h3 className="h3 lato-heavy green">ANTIPSICÓTICO</h3>
            <p className="mb-0">
              Las pruebas sugieren que Alivia ayuda a pacientes con
              esquizofrenia y otros problemas de salud mental, como el trastorno
              bipolar.
            </p>
          </div>
        </div>
        <div className="row items-container row-last">
          <div className="col-sm-4 col-md-3 col-md-offset-1 text-center item box item-7">
            <div className="icon inline-block">
              <span className="green pull-right lato-bold rotate-90 hidden-xs hidden-sm right">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-green.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Mejora tu sistema <br />
                inmunológico
              </span>
              <img
                src={process.env.PUBLIC_URL + "img/icon-6.png"}
                className="img-responsive mx-auto relative"
                alt="icon"
              />
            </div>
            <h3 className="h3 lato-heavy orange">ACNÉ</h3>
            <p className="mb-0">
              Alivia actúa sobre las glándulas sebáceas humanas produciendo una
              “trío” de respuestas celulares frente al acné, que se podrían
              utilizar para reducir significativamente la gravedad de muchos
              casos
            </p>
          </div>
          <div className="col-sm-4 text-center item box item-8">
            <div className="icon inline-block">
              <span className="orange pull-right lato-bold rotate-90 hidden-xs hidden-sm right">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-orange.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Previene la Diabetes <br />y sobrepeso
              </span>
              <img
                src={process.env.PUBLIC_URL + "img/icon-5.png"}
                width="220"
                className="img-responsive mx-auto relative"
                alt="icon-5"
              />
            </div>
            <h3 className="h3 lato-heavy green">DIABETES</h3>
            <p className="mb-0">
              Nuestro producto Alivia se basa en su potencial capacidad para
              regular los niveles de azúcar en sangre de aquellas personas con
              distintos tipos de Diabetes, ya sea por predisposición genética o
              debido a un balance inadecuado crónico de nutrientes en la dieta.
            </p>
          </div>
          <div className="com-sm-4 col-md-3 text-center item box item-9">
            <div className="icon inline-block">
              <span className="green pull-right lato-bold rotate-90 hidden-xs hidden-sm left">
                <img
                  src={process.env.PUBLIC_URL + "img/plus-green.png"}
                  width="18"
                  height="18"
                  className="mr-2"
                  alt="plus"
                />
                Fortalece tu corazón
              </span>
              <img
                alt="icn-4"
                src={process.env.PUBLIC_URL + "img/icon-4.png"}
                className="img-responsive mx-auto relative"
              />
            </div>
            <h3 className="h3 lato-heavy orange">REUMATISMO</h3>
            <p className="mb-0">
              Tiene la capacidad de activar los receptores que se encuentran
              dentro de nuestros cuerpos. Gracias a esto, se produce una cascada
              de múltiples efectos que pueden ayudar con el manejo de dolor
              crónico e inflamación.
            </p>
          </div>
        </div>
        <div className="msg-bottom text-center hidden-xs">
          <h4 className="h2 mt-0 mb-0 green lato-heavy bottommsg">
            <span>
              El secreto mejor guardado <br className="hidden-xs" />
              ahora está a tu alcance.
            </span>
          </h4>
        </div>
        <div className="envio-gratis-2 text-center">
          <a href="tel:1-855-862-0427" className="hover-action">
            <img
              alt="cell"
              src={process.env.PUBLIC_URL + "img/envio-gratis-2.png"}
              className="img-responsive mx-auto relative"
            />
          </a>
        </div>
        <div className="text-center visible-xs col-xs-12 mb-6 mt-6">
          <img
            alt="botellas"
            src={process.env.PUBLIC_URL + "img/botellas-alivia.png"}
            className="img-responsive"
          />
        </div>
      </div>
    </section>
  );
}
