import React from "react";

function Header() {
  return (
    <>
      <div className="top-bar bg-yellow py-2 fixed">
        <div className="container text-center">
          <p className="mb-0 red lato-heavy ls-5 change-color">
            <a href="tel:1-855-862-0427">
              Llama ya
              <br /> 1-855-862-0427
            </a>
            <a
              href="/"
              className="close-bar hidden-xs pull-right red lato-heavy"
            >
              <img
                src={process.env.PUBLIC_URL + "/img/close.png"}
                alt="Cerrar"
              />
            </a>{" "}
          </p>
        </div>
      </div>
      <header
        className="header full-width absolute"
        role="navigation"
        id="home"
      >
        <div className="container clearfix">
          <nav className="navbar navbar-default">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar-collapse-3"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="logo pull-left" href="#home">
              <img
                src={process.env.PUBLIC_URL + "/img/logo-gano.png"}
                className="img-responsive"
                alt="logo"
              />
            </a>
            <nav
              className="collapse navbar-collapse menu pull-right"
              id="navbar-collapse-3"
            >
              <ul className="mb-0 nav navbar-nav navbar-right">
                <li className="montserrat text-uppercase">
                  <a href="#beneficios" className="white">
                    Beneficios
                  </a>
                </li>
                <li className="montserrat text-uppercase">
                  <a href="#testimonios" className="white">
                    Testimonios
                  </a>
                </li>
                <li className="montserrat text-uppercase btn-wrapper">
                  <a
                    href="tel:1-855-862-0427"
                    className="btn btn-primary ordena-ya"
                  >
                    <img
                      alt="phone"
                      className="mr-1"
                      src={process.env.PUBLIC_URL + "/img/phone.png"}
                      style={{ filter: "hue-rotate(100deg)" }}
                    />
                    1-855-862-0427
                  </a>
                </li>
              </ul>
            </nav>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Header;
