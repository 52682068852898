import React from "react";

export default function Testimonials() {
  return (
    <section id="testimonios" className="testimonials shadow">
      <div className="container">
        <div className="text-center">
          <h2 className="main-title inline-block white underline mb-0 mt-0 lato-bold relative">
            Testimonios
          </h2>
        </div>
        <div className="clearfix no-gutter testimonials-slide">
          <div className="col-sm-4 text-center">
            <div className="item box text-center mb-4">
              <p className="yellow-dark lato-italic h4">
                “Fantástico Realmente me ayuda mucho. Sufro de mal temperamento
                … un poco de depresión, angustia y ansiedad. Empecé a tomar
                Alivia, e incluso mi ansiedad, está mejorando. Parece que
                recuperé mi vida. Gracias.”
              </p>
            </div>
            <span className="lato-italic h4 bold inline-block author white underline relative gray">
              NORIS PORTILLO BERNAL
            </span>
          </div>
          <div className="col-sm-4 text-center">
            <div className="item box text-center mb-4">
              <p className="yellow-dark lato-italic h4">
                “Sufro de sofocos debido a la menopausia. También he aumentado
                la ansiedad debido al prolactinoma. Alivia reduce
                significativamente mis sofocos y la ansiedad, casi por completo.
                Este producto es un salvavidas milagroso para mí. Para las
                mujeres que sufren de sofocos, este es el verdadero negocio.”
              </p>
            </div>
            <span className="lato-italic h4 bold inline-block author white underline relative gray">
              LUCIANA GUERRERO
            </span>
          </div>
          <div className="col-sm-4 text-center">
            <div className="item box text-center mb-4">
              <p className="yellow-dark lato-italic h4">
                “El spray Alivia realmente ha logrado reducir mis sentimientos
                de ansiedad. Gracias por sus productos de alta calidad.”
              </p>
            </div>
            <span className="lato-italic h4 bold inline-block author white underline relative gray">
              FABIANO PONCE RODRÍGUEZ
            </span>
          </div>
          <div className="col-sm-4 text-center">
            <div className="item box text-center mb-4">
              <p className="yellow-dark lato-italic h4">
                “Comencé a usar este producto hace poco más de tres meses y
                estoy muy contenta con los resultados. Sufría de constantes
                dolores de cabeza que me dificultaban concentrarme. Después de
                probar el aceite Alivia, mi calidad de vida ha cambiado para
                mejor.”
              </p>
            </div>
            <span className="lato-italic h4 bold inline-block author white underline relative gray">
              RITA YATES
            </span>
          </div>
        </div>
        <div className="clearfix"></div>
        <img
          src={process.env.PUBLIC_URL + "img/bg-bottom-2-icon.png"}
          className="img-responsive mx-auto icon-bottom"
          alt="bg"
        />
      </div>
    </section>
  );
}
