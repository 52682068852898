import React from "react";

export default function Promo() {
  return (
    <>
      <div className="top-bar bg-yellow-dark py-2 visible-xs">
        <div className="container text-center">
          <p className="mb-0 brown montserrat-semibold ls-10">ALIVIA</p>
        </div>
      </div>
      <section id="promo" className="promo shadow" style={{ display: "flex" }}>
        <div className="col-sm-8 col-md-6 f-n text-center mx-auto white">
          <div className="envio-gratis px-4 text-center">
            <a href="tel:1-855-862-0427" className="hover-action">
              <img
                alt="promo-1"
                src={process.env.PUBLIC_URL + "/img/envio-gratis-2.png"}
                className="img-responsive mx-auto"
              />
            </a>
          </div>
          <div className="frascos lh-0">
            <img
              alt="promo-2"
              src={process.env.PUBLIC_URL + "/img/frascos-alivia.png"}
              className="img-responsive"
            />
          </div>
        </div>
      </section>
    </>
  );
}
