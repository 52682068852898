import React from "react";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function Privacity() {
  const [open, setOpen] = React.useState(false);

  // @ts-ignore
  const handleClose = (value) => {
    setOpen(value);
  };

  return (
    <div>
      <Link
        to="/"
        data-toggle="modal"
        data-target=".bs-example-modal-lg"
        data-content="content-politicas"
        className="yellow-dark  trigger-modal"
        onClick={() => handleClose(true)}
      >
        Politicas de Privacidad
      </Link>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        scroll="body"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={0}>
            <div className="modal-body content-politicas">
              <h1 className="h3 lato-bold green">Políticas de Privacidad</h1>
              <p>
                Nuestra política de satisfacción del 100% garantizada asegura
                que cualquier producto que usted compre cumple con los más altos
                estándares. Por alguna razón usted no está satisfecho con el
                producto (s), usted puede fácilmente y rápidamente cambiar o
                devolver el artículo (s) dentro de los 30 días de la compra sin
                problemas. Cada producto debe ser devuelto en perfecto estado,
                sin uso, con las etiquetas atadas y en su embalaje original.
              </p>
              <p>
                <strong>Devoluciones</strong> Para las devoluciones, el importe
                total del artículo (s) devuelto será acreditado a la tarjeta
                (menos los gastos de manejo y envió) utilizada en la transacción
                original. Por favor permita 2-3 días hábiles para esta cantidad
                para reflejar en su cuenta. Debe enviar el producto:
              </p>
              <p>
                <strong>Lugar a referirse.</strong>
              </p>
              <p>
                <strong>Política de envíos</strong> Todas las órdenes se envían
                dentro de las 48 horas de lunes - viernes de 8am - 5pm. Enviamos
                todos los pedidos a través de los servicios USPS, si por
                determinada razón un numero de seguimiento No es proporcionada
                por el portador del envío, actualizaremos su pedido con la
                información de seguimiento.
              </p>
              <p>––––––––––––––––––––</p>
              <p>
                Our 100% satisfaction guaranteed policy ensures that any product
                you purchase meets the highest standards. For whatever reason
                you are not satisfied with the product(s), you can easily and
                quickly exchange or return the item(s) within 30 days of
                purchase hassle free. Each product should be returned in perfect
                condition, unworn, unopened with tags attached and in its
                original packaging.
              </p>
              <p>
                <strong>Returns</strong> For returns, the total amount of the
                returned item(s) will be credited back to your card used in the
                original transaction (less shipping and handle fees). Please
                allow 2 to 3 business days for this amount to reflect in your
                account. You must send the product:
              </p>
              <p>
                <strong>Shipping Policy</strong> All orders are shipped within
                48 hours Monday - Friday 8am - 5pm. We ship all the orders using
                USPS, If a tracking # is provided by the shipping carrier, we
                will update your order with the tracking information.
              </p>
              <p>
                <strong>Place to refer.</strong>
              </p>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
