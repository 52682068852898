import React from "react";

// Chilc Components
import Privacity from "./privacity";
import Terms from "./terms";

function Footer() {
  return (
    <>
      <footer className="footer shadow">
        <a href="#home" className="gotop ease">
          <img
            src={process.env.PUBLIC_URL + "/img/go-top.png"}
            width="55"
            height="55"
            alt="promo"
          />
        </a>
        <div className="container">
          <div className="text-center">
            <a
              href="tel:1-855-862-0427"
              className="mx-auto text-center green inline-block ordenar-bottom hover-action"
            >
              <img
                src={process.env.PUBLIC_URL + "/img/ordena-bottom-3.png"}
                className="img-responsive mx-auto relative call"
                alt="call"
              />
            </a>
          </div>
          <div className="content-footer text-center">
            <h5
              className="h2 inline-block mt-0 mb-0 parentesis lato-bold white"
              style={{ color: "#FFF" }}
            >
              Fortalece tu sistema inmunológico
            </h5>
            <div className="clearfix"></div>
            <ul className="mb-0 menu-footer inline-block list-unstyled list-inline">
              <li>
                <a href="tel:1-855-862-0427" className="yellow-dark ">
                  Ordena ahora
                </a>
              </li>
              <li>
                <Privacity />
              </li>
              <li>
                <Terms />
              </li>
            </ul>
            <div className="clearfix"></div>
            <p className="mb-0 credits  white">TODOS LOS DERECHOS RESERVADOS</p>
          </div>
        </div>
      </footer>
      <div className="interstitial">
        <p>Ordena ya los beneficios que solo te ofrece Alivia</p>
        <div className="btn btn-default">1-855-862-0427</div>
      </div>
    </>
  );
}

export default Footer;
