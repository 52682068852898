import React from "react";

// Child Components
import Hero from "../../../components/hero";
import Benefits from "../../../components/benefits/circle";
import Promo from "../../../components/promo";
import BenefitsExtends from "../../../components/benefits/extends";
import Persons from "../../../components/persons";
import Testimonials from "../../../components/testimonials";

export default function HomePage() {
  return (
    <>
      <Hero />
      <Benefits />
      <Promo />
      <BenefitsExtends />
      <Persons />
      <Testimonials />
    </>
  );
}
